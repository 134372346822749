<template>
  <div>
    <h4>Toilettage Activity</h4>
          
    <data-table
      :data-source="sortedActivities"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          data-toggle="modal"
          data-target="#add-toilettage-activity"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
              
              
              
    <modal
      title="Ajout d'une activité de toilettage"
      id="add-toilettage-activity"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="adding"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="add-libelle">Libelle</label>
          <input
            id="add-libelle"
            name="add-libelle"
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>
        <div class="row">
          <div class="col-8">
            <div class="form-group">
              <label for="add-prix">Prix</label>
              <input
                id="add-prix"
                name="add-prix"
                type="text"
                class="form-control"
                v-model="prix"
              >
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="add-currency">Devise (monaie)</label>
              <select
                class="form-control"
                v-model="currency"
                v-select="{placeholder: 'Selectionnez la devise'}"
              >
                <option />
                <option
                  v-for="(c , i) in availableCurrencies"
                  :key="i"
                  :value="c.value"
                >
                  {{ c.libelle }}
                </option>
              </select>
            </div>
          </div>
        </div>

          
                      
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Ajouter
          </button>
        </div>
      </form>
    </modal>
      
    <modal
      title="Modification de l'activité de toilettage"
      id="update-toilettage-activity"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="editing"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="update-libelle">Libelle</label>
          <input
            id="update-libelle"
            name="update-libelle"
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>

        <div class="row">
          <div class="col-8">
            <div class="form-group">
              <label for="update-prix">Prix</label>
              <input
                id="update-prix"
                name="update-prix"
                type="text"
                class="form-control"
                v-model="prix"
              >
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="update-currency">Devise (monaie)</label>
              <select
                class="form-control"
                v-model="currency"
                v-select="{placeholder: 'Selectionnez la devise'}"
              >
                <option />
                <option
                  v-for="(c , i) in availableCurrencies"
                  :key="i"
                  :value="c.value"
                >
                  {{ c.libelle }}
                </option>
              </select>
            </div>
          </div>
        </div>
                      
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Enregistrer
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>
      
      <script>
      import {mapGetters, mapMutations} from 'vuex'
      import DataTable from '../../../../components/dataTable/local.vue'
      import { COMPONENT_TYPE, TEXT_TYPE } from '../../../../components/dataTable/dataType'
      import Modal from '../../../../components/modal.vue'
      import { Select } from '../../../../directive/select'
      import { ADD_TOILETTAGE_ACTIVITY, UPDATE_TOILETTAGE_ACTIVITY } from '../../../../graphql/sanitaire'
      const Actions = () => import('../../../../components/admin/sanitaire/toilettage/actions.vue')
      import {CURRENCIES} from '../../../../constants/app'
      export default {
          components: { DataTable, Modal},
          directives: {Select},
          data(){
              return {
                  libelle: null,
                  prix: 0,
                  currency: null,
                  has_error: false,
                  error_msg: null
              }
          },
          watch: {
            selectedObject: {
                  handler(){
                      if(this.selectedObject !== null) {
                          this.libelle = this.selectedObject.libelle
                          this.prix = this.selectedObject.prix
                          this.currency = this.selectedObject.currency
                      }
                  },
                  deep: true
              }
          },
          methods: {
              ...mapMutations({
                  done: 'DONE',
                  setSelectedObject: 'SET_SELECTED_OBJECT'
              }),
              initForm(){
                  this.setSelectedObject(null)
                  this.libelle = null
                  this.prix = 0
                  this.currency = null
                  this.has_error = false
                  this.error_msg = null
              },
              adding(){
                  let data = {
                      libelle: this.libelle,
                      prix: parseInt(this.prix),
                      currency: this.currency
                  }
                  this.$apollo.mutate({
                      mutation: ADD_TOILETTAGE_ACTIVITY,
                      variables: {
                          "activity": {
                              ...data
                          }
                      },
                      update: (d) => {
                          console.log(d)
                          console.log(`Toilettage activity ${this.libelle} add successfully`)
                      }
                    }).then(() => {
                      this.initForm()
                      this.done()
                  }).catch((error) => {
                      console.error(error)
                      this.has_error = true
                      this.error_msg = error
                  })
              },
              editing(){
                  let data = {
                      libelle: this.libelle,
                      prix: parseInt(this.prix),
                      currency: this.currency
                  }
                  this.$apollo.mutate({
                      mutation: UPDATE_TOILETTAGE_ACTIVITY,
                      variables: {
                          "activity": {
                              ...data
                          },
                          "uid": this.selectedObject.uid
                      },
                      update: (d) => {
                          console.log(d)
                          console.log(`Toilettage activity ${this.libelle} on ${this.selectedObject.uid} updated successfully`)
                      }
                    }).then(() => {
                      this.initForm()
                      this.done()
                  }).catch((error) => {
                      console.error(error)
                      this.has_error = true
                      this.error_msg = error
                  })
              }
          },
          computed: {
              ...mapGetters({
                  activities: 'sanitaire/toilettageActivities',
                  selectedObject: 'selectedObject'
              }),
              sortedActivities(){
                return [...this.activities].sort((a,b) => a.libelle.localeCompare(b.libelle))
              },
              availableCurrencies(){
                return CURRENCIES
              },
              headers(){
                  return [
                      {label: 'ID', name: 'uid', type: TEXT_TYPE},
                      {label: 'Libelle', name: 'libelle', type: TEXT_TYPE},
                      {label: 'Prix', name: 'prix', type: TEXT_TYPE},
                      {label: 'Devise (monaie)', name: 'currency', type: TEXT_TYPE},
                      {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
                  ]
              }
          }
      }
      </script>
      
      <style>
      
      </style>